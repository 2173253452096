import { useQuery } from '@tanstack/react-query';
import { useModulesStore } from '@wkda/funnel-components';

/**
 * @param {number} updateInterval set the refetch interval in milliseconds
 **/
export function useGetAveragePriceCounter(updateInterval) {
  const _updateInterval = updateInterval !== null && updateInterval !== void 0 ? updateInterval : 10 /* minutes */ * 60 * 1000;
  const {
    consumerModule: {
      getAveragePriceCounter
    }
  } = useModulesStore();
  return useQuery({
    queryKey: ['averaged-priced-counter'],
    queryFn: getAveragePriceCounter,
    staleTime: _updateInterval,
    refetchInterval: _updateInterval
  });
}