import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
export function useInterval(callback, delay) {
  const callbackRef = useRef(callback);
  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffect(() => {
    if (typeof delay !== 'number' || Number.isNaN(delay)) {
      return null;
    }
    const id = setInterval(() => {
      var _callbackRef$current;
      (_callbackRef$current = callbackRef.current) === null || _callbackRef$current === void 0 || _callbackRef$current.call(callbackRef);
    }, delay);
    return function clean() {
      return clearInterval(id);
    };
  }, [delay]);
}