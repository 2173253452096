import { formNames } from '@wkda/carlead-creation-form';
import { CarleadFieldsMap } from '../carleadFieldsMap';
import { leaveOnlyNumber } from '@wkda/funnel-utils';

/**
 * Mapping object that maps form names to their corresponding select item IDs or custom transformation functions.
 */
const DSBToSFMap = {
  [formNames.manufacturer]: selectItemId(formNames.manufacturer),
  [formNames.mainType]: selectItemId(formNames.mainType),
  [formNames.builtDate]: selectItemId(formNames.builtDate),
  [formNames.bodyType]: selectItemId(formNames.bodyType),
  [formNames.mainTypeDetail]: selectItemId(formNames.mainTypeDetail),
  [formNames.mainTypeSub]: selectItemId(formNames.mainTypeSub),
  [formNames.mileage]: selectItemId(formNames.mileage),
  [formNames.fuelType]: selectItemId(formNames.fuelType),
  [formNames.horsePower]: selectItemId(formNames.horsePower),
  [formNames.gearType]: selectItemId(formNames.gearType),
  [formNames.doorCount]: selectItemId(formNames.doorCount),
  [formNames.location]: d => {
    var _d$zipCode, _d$zipCode2;
    return d !== null && d !== void 0 && (_d$zipCode = d.zipCode) !== null && _d$zipCode !== void 0 && (_d$zipCode = _d$zipCode.selected) !== null && _d$zipCode !== void 0 && _d$zipCode.label && d !== null && d !== void 0 && (_d$zipCode2 = d.zipCode) !== null && _d$zipCode2 !== void 0 && (_d$zipCode2 = _d$zipCode2.selected) !== null && _d$zipCode2 !== void 0 && _d$zipCode2.value ? {
      label: d.zipCode.selected.label,
      lat: d.zipCode.selected.value.lat,
      lng: d.zipCode.selected.value.lng
    } : null;
  },
  [formNames.email]: d => {
    var _d$email;
    return (d === null || d === void 0 || (_d$email = d.email) === null || _d$email === void 0 ? void 0 : _d$email.selected) || '';
  },
  [formNames.licensePlate]: d => {
    var _d$licensePlate;
    return d === null || d === void 0 || (_d$licensePlate = d.licensePlate) === null || _d$licensePlate === void 0 ? void 0 : _d$licensePlate.value;
  }
};
export function mapFromDSB(formData, questionnaireData, config) {
  return {
    ...mapFromDSBForm(formData, config),
    ...mapFromDSBQuestionnaire(questionnaireData)
  };
}
function mapFromDSBForm(data, config) {
  let _map = DSBToSFMap;
  if (config.exactMileage) {
    _map = {
      ..._map,
      [formNames.mileage]: d => {
        var _d$stepCompoundForm, _d$stepCompoundForm2;
        return d !== null && d !== void 0 && (_d$stepCompoundForm = d.stepCompoundForm) !== null && _d$stepCompoundForm !== void 0 && (_d$stepCompoundForm = _d$stepCompoundForm.exactMileage) !== null && _d$stepCompoundForm !== void 0 && _d$stepCompoundForm.value ? leaveOnlyNumber(d === null || d === void 0 || (_d$stepCompoundForm2 = d.stepCompoundForm) === null || _d$stepCompoundForm2 === void 0 || (_d$stepCompoundForm2 = _d$stepCompoundForm2.exactMileage) === null || _d$stepCompoundForm2 === void 0 ? void 0 : _d$stepCompoundForm2.value) : '';
      }
    };
  }
  return Object.entries(_map).reduce((acc, _ref) => {
    let [key, fn] = _ref;
    const mapped = fn(data);
    return {
      ...acc,
      [key]: mapped
    };
  }, {});
}
function selectItemId(fieldName) {
  return function _selectItemId(d) {
    var _d$stepCompoundForm3, _d$stepCompoundForm4;
    //@ts-expect-error
    const value = CarleadFieldsMap.toDSB(fieldName);
    return value === undefined || value === 'email' || value === 'licensePlate' || value === 'zipCode' ? '' : value === 'exactMileage' ? itemValueSelector(d === null || d === void 0 || (_d$stepCompoundForm3 = d.stepCompoundForm) === null || _d$stepCompoundForm3 === void 0 ? void 0 : _d$stepCompoundForm3[value]) : itemIdSelector(d === null || d === void 0 || (_d$stepCompoundForm4 = d.stepCompoundForm) === null || _d$stepCompoundForm4 === void 0 ? void 0 : _d$stepCompoundForm4[value]);
  };
}
function itemIdSelector(d) {
  return (d === null || d === void 0 ? void 0 : d.selectedId) || '';
}
function itemValueSelector(d) {
  return (d === null || d === void 0 ? void 0 : d.value) || '';
}
function mapFromDSBQuestionnaire(data) {
  var _data$flexibleQuestio;
  return data === null || data === void 0 || (_data$flexibleQuestio = data.flexibleQuestionnaire) === null || _data$flexibleQuestio === void 0 || (_data$flexibleQuestio = _data$flexibleQuestio.customerSurvey) === null || _data$flexibleQuestio === void 0 || (_data$flexibleQuestio = _data$flexibleQuestio.questions) === null || _data$flexibleQuestio === void 0 ? void 0 : _data$flexibleQuestio.reduce((questionnaire, question) => ({
    ...questionnaire,
    [question.questionId]: question.answerId
  }), {});
}