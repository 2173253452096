import { formNames } from '@wkda/carlead-creation-form';
import { formatThousand, isValidEmailAddress } from '@wkda/funnel-utils';
import flow from 'lodash-es/flow';
import set from 'lodash-es/set';
import { CarleadFieldsMap } from '../carleadFieldsMap';
export function mapToDSB(formValues, config) {
  let _map = SFToDSBMap;
  if (config.exactMileage) {
    // @ts-expect-error
    _map[formNames.mileage] = mapExactMileage(config);
  }
  const formData = Object.entries(_map).reduce((acc, _ref) => {
    let [key, fn] = _ref;
    const mapped = fn(formValues[key]);
    const mappedKey = mapped.key;
    if (mappedKey) {
      set(acc, mappedKey, mapped.value);
    }
    return acc;
  }, {});
  return {
    formData,
    questionnaire: getDSBQuestionnaire(formValues)
  };
}
function getSFQuestionnaireKeys(formValues) {
  return Object.keys(formValues).filter(key => {
    return !formNames[key];
  });
}
function getSFQuestionnaire(formValues) {
  return keys => {
    return keys.reduce((questionnaire, key) => {
      return {
        ...questionnaire,
        [key]: formValues[key]
      };
    }, {});
  };
}
function mapSFQuestionnaireToDSB(questionnaire) {
  return {
    flexibleQuestionnaire: {
      customerSurvey: {
        questions: Object.entries(questionnaire).reduce((questions, _ref2) => {
          let [key, value] = _ref2;
          questions.push({
            questionId: key,
            answerId: value
          });
          return questions;
        }, [])
      }
    }
  };
}
function getDSBQuestionnaire(formValues) {
  return flow(getSFQuestionnaireKeys, getSFQuestionnaire(formValues), mapSFQuestionnaireToDSB)(formValues);
}
function parseLocation(location) {
  if (typeof location === 'string') {
    try {
      return JSON.parse(location);
    } catch (e) {
      return null;
    }
  }
  return location;
}
function mapLocation(value) {
  return {
    key: CarleadFieldsMap.toDSB(formNames.location),
    value: {
      selected: {
        label: value === null || value === void 0 ? void 0 : value.label,
        value: {
          lat: value === null || value === void 0 ? void 0 : value.lat,
          lng: value === null || value === void 0 ? void 0 : value.lng
        }
      }
    }
  };
}
function mapExactMileage(config) {
  return function (value) {
    return {
      key: getStepCompoundFormPath(formNames.exactMileage),
      value: {
        value: formatThousand(config.locale, value),
        validated: true
      }
    };
  };
}
function getStepCompoundFormPath(key) {
  //@ts-expect-error
  return "stepCompoundForm." + CarleadFieldsMap.toDSB(key);
}
const SFToDSBMap = {
  [formNames.manufacturer](value) {
    return {
      key: getStepCompoundFormPath(formNames.manufacturer),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.mainType](value) {
    return {
      key: getStepCompoundFormPath(formNames.mainType),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.builtDate](value) {
    return {
      key: getStepCompoundFormPath(formNames.builtDate),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.bodyType](value) {
    return {
      key: getStepCompoundFormPath(formNames.bodyType),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.mainTypeDetail](value) {
    return {
      key: getStepCompoundFormPath(formNames.mainTypeDetail),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.mainTypeSub](value) {
    return {
      key: getStepCompoundFormPath(formNames.mainTypeSub),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.mileage](value) {
    return {
      key: getStepCompoundFormPath(formNames.mileage),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.location]: flow(parseLocation, mapLocation),
  [formNames.email](value) {
    return {
      key: CarleadFieldsMap.toDSB(formNames.email),
      value: {
        selected: value,
        validated: isValidEmailAddress(value)
      }
    };
  },
  [formNames.licensePlate](value) {
    return {
      key: CarleadFieldsMap.toDSB(formNames.licensePlate),
      value: {
        value: value !== null && value !== void 0 ? value : ''
      }
    };
  },
  [formNames.fuelType](value) {
    return {
      key: getStepCompoundFormPath(formNames.fuelType),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.doorCount](value) {
    return {
      key: getStepCompoundFormPath(formNames.doorCount),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.horsePower](value) {
    return {
      key: getStepCompoundFormPath(formNames.horsePower),
      value: {
        selectedId: value
      }
    };
  },
  [formNames.gearType](value) {
    return {
      key: getStepCompoundFormPath(formNames.gearType),
      value: {
        selectedId: value
      }
    };
  }
};