import { getDsbLocalStorageKey, tryCatch } from '@wkda/funnel-utils';
import { mapFromDSB } from './mapFromDSB';
import { mapToDSB } from './mapToDSB';
export class DSBDataLocalStorage {
  constructor(storage, config) {
    this.storage = storage;
    this.config = config;
  }
  get formStorageKey() {
    return getDsbLocalStorageKey(this.config.fullLocale);
  }
  get questionnaireStorageKey() {
    return "dsb-core-flexible-questionnaire_" + this.config.fullLocale + "_v1";
  }
  getItem(name) {
    const formData = this.storage.getItem(name);
    const questionnaireData = this.storage.getItem(this.questionnaireStorageKey);
    return JSON.stringify(mapFromDSB(formData, questionnaireData, this.config));
  }
  setItem(name, value) {
    try {
      const {
        formData,
        questionnaire
      } = mapToDSB(JSON.parse(value), this.config);
      const prevData = tryCatch(() => {
        //@ts-expect-error we are catching the error
        return JSON.parse(this.storage.getItem(name));
      }, () => {});
      this.storage.setItem(name, {
        ...prevData,
        ...formData
      });
      this.storage.setItem(this.questionnaireStorageKey, questionnaire);
    } catch (e) {
      console.error('[Form Cache] Error saving data', e);
    }
  }
  removeItem(name) {
    this.storage.removeItem(name);
    this.storage.removeItem(this.questionnaireStorageKey);
  }
}